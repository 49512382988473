import React, { useState, useEffect, useRef, useCallback} from "react";
import Map, { FlyToInterpolator, Source, Layer, Popup } from "react-map-gl";
// import * as THREE from 'three';
// import { Threebox } from 'threebox-plugin'; 
// import "threebox-plugin/dist/threebox.css";
// import radar from '../static/radar.glb';
// import car from '../static/car.glb';
import "mapbox-gl/dist/mapbox-gl.css";
import { io } from "socket.io-client";
import RsuBar from "../components/Rsu/RsuBar";
import WsuBar from "../components/Wsu/WsuBar";
import Vam from "../components/Map/Pins/Vam";
import Vsm from "../components/Map/Pins/Vsm";
import RsuTabInfo from "../components/Rsu/RsuTabInfo";
import RsuMessageList from "../components/Rsu/RsuMessageList";
import RsuTollingZonesList from "../components/Rsu/RsuTollingZones";
import TumMessageList from "../components/Tum/TumMessageList";
import WsuMessageList from "../components/Wsu/WsuMessageList";
import WsuTabInfo from "../components/Wsu/WsuTabInfo";
import CustomNav from "../components/Nav/CustomNav";
import CustomNavWSU from "../components/Nav/CustomNavWSU";
import CustomNavAdmin from "../components/Nav/CustomNavAdmin";
import CustomNavStatistics from "../components/Nav/CustomNavStatistics";
import CustomNavWeather from "../components/Nav/CustomNavWeather";
import CustomNavTAM from "../components/Nav/CustomNavTAM";
import RSU from "../components/Map/Pins/RsuStation";
import WSU from "../components/Map/Pins/WeatherStation";
import Cpm from "../components/Map/Pins/Cpm";
import Cam from "../components/Map/Pins/Cam";
import Bsm from "../components/Map/Pins/Bsm";
import Tum from "../components/Map/Pins/Tum";
import TumPopup from "../components/Map/Pins/TumPopup";
import DashArrayAnimation from "../components/Map/Animations/DashArrayAnimation";
import { useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import AlertForm from "../components/Alert/AlertForm";
import UpdateForm from "../components/Alert/UpdateForm";
import Denm from "../components/Map/Pins/Denm";
import DisplayAlerts from "../components/Alert/DisplayAlerts";
import CameraStream from "../components/Stream/CameraStream";
import PinPoint from "../components/Map/Pins/PinPoint";
import DashboardsList from "../components/Dashboard/DashboardsList";
import TrafficChartTab from "../components/Dashboard/TrafficChartTab";
import WeatherChartTab from "../components/Dashboard/WeatherChartTab";
import randomLocation from "random-location";
import radars from "../utils/radars.json";
import weather_stations from "../utils/weather_stations.json";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import {RsuIDInfoDiv, WsuIDInfoDiv, MapContainer, RsuTabIcon, CreateAlertTabIcon, DisplayAlertTabIcon, SelectTabRel, StationsTabRel, RsuMainDiv, RsusTabInfoDiv, WsusTabInfoDiv, 
        StatisticsDiv, HeaderStatistics, ArrowCloseOpen, RightTab, RightTabHeaderDiv, RightTabHeaderDivHiddenOnMobile, ChartsContainer, WeatherStatisticsDiv, ToggleContainer, Label,
        PopupContent, PopupTitle, PopupList, PopupListItem} from '../styles/map_page_styles.js';
import Cookies from "js-cookie";
import { AuthContext } from '../components/Auth/AuthContext';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

function Map_page() {
  const [rsuList, setRsuList] = useState([]);
  const [wsuList, setWsuList] = useState([]);
  const [cars, setCars] = useState([]);
  const [RsuToggled, setRsuToggled] = useState(false);
  const [WsuToggled, setWsuToggled] = useState(false);
  const [idx_RSU, setRsuIdx] = useState(null);
  const [idx_WSU, setWsuIdx] = useState(null);
  const [idx_radar, setRadarIdx] = useState(null);
  const [idx_weather, setWeatherIdx] = useState(null);
  const [radarToggled, setRadarToggled] = useState(false);
  const [weatherToggled, setWeatherToggled] = useState(false);
  const [camList, setCamList] = useState([]);
  const [bsmList, setBsmList] = useState([]);
  const [selectedAlertImg, setAlertImg] = useState(false);
  const [alertList, updateAlertList] = useState([]);
  const [vamList, updateVamList] = useState([]);
  const [vsmList, updateVsmList] = useState([]);
  const [tamList, updateTamList] = useState([]);
  const [tumList, updateTumList] = useState([]);
  const [tabIndex, setTabIdx] = useState(0);
  const [activeSectionRsu, setActiveSectionRsu] = useState("Heartbeats");
  const [activeSectionWsu, setActiveSectionWsu] = useState("Heartbeats");
  const [activeSectionTAM, setActiveSectionTAM] = useState("TollInfo");
  const {isAuthenticated, checkAuthentication} = useContext(AuthContext);
  const [clickedAlertId, setClickedAlertId] = useState('');
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(null);
  const [activeSectionStat, setActiveSectionStat] = useState("NumberOfVehicles");
  const [activeSectionWeather, setActiveSectionWeather] = useState("AverageMet");
  const [closePopup, setClosePopup] = useState(false);
  const [selectedCPM, setSelectedCPM] = useState(null);
  const [is3D, setIs3D] = useState(false);
  const [popupInfoTAM, setPopupInfoTAM] = useState(null);
  const [popupDataTUM, setPopupDataTUM] = useState(null);

  const cpm_radars = { ...radars };
  const radar_keys = Object.keys(cpm_radars);

  const wsu_list = { ...weather_stations };
  const wsu_keys = Object.keys(wsu_list);

  // Define tabIndex constants
  const TAB_INDEX_NONE = 0;
  const TAB_INDEX_RSU_LIST = 1;
  const TAB_INDEX_WSU_LIST = 2;
  const TAB_INDEX_CREATE_ALERT = 3;
  const TAB_INDEX_ALERTS_HISTORY = 4;
  const TAB_INDEX_UPDATE_ALERT = 5;
  const TAB_INDEX_DASHBOARD = 6;


  const [mapCenter, setMapCenter] = useState({
    latitude: 40.627484297,
    longitude: -8.7103187099,
  });

  const handleCPMClick = (cpmData) => {
    const matchingRsu = rsuList.find((rsu) => rsu._id === cpmData.rsu_id);
    const updatedCpmData = { ...cpmData, matchingRsu };
    // const car = cars[matchingRsu._id].find((c) => c[5] === cpmData.cpm_id);
    // console.log("CPM DATA: ", cpmData)
    // console.log(car)
    setSelectedCPM(updatedCpmData);
  };

  const clearSelectedCPM = (cpm_id) => {
    if(selectedCPM && (cpm_id === selectedCPM.cpm_id)) { 
      setSelectedCPM(null);
    }
  };

  const selectedCar = selectedCPM && cars[selectedCPM.rsu_id].find((c) => c[5] === selectedCPM.cpm_id);

  function handleAlertClick(alertId, alertClicked) {
    setClickedAlertId(alertId);
    window.dispatchEvent(new CustomEvent('alertClicked', { detail: alertId }));
  }

  async function handleCancelAlert(alert) {
    const isAuth = await checkAuthentication();

    if(isAuth) {
      const token = Cookies.get('token');
      console.log("[info] cancelling alert ID:", alert._id);
      try {
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_PREFIX}/alert/cancel`,
          data: {
            alert_id: alert._id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        notify("The alert was successfully canceled.");

      } catch (error) {
        notify("An error occurred while cancelling the alert.", "error");
        console.error("[error] while canceling alert:", error);
      }
    }
    else {
      notify("Your session has expired, please relogin.", "error",)
    }
  }

  function handleUpdateAlert(alert) {
    setShowUpdateAlert(true);
    setUpdateAlert(alert);
    setAlertMarker({latitude: alert.latitude, longitude: alert.longitude, color: "green"});
    setTabIdx(TAB_INDEX_UPDATE_ALERT);
  }

  const cancelUpdate = () => {
    setShowUpdateAlert(false);
  };

  async function submitUpdateAlert(alert) {
      setShowUpdateAlert(false);
      const isAuth = await checkAuthentication();

      if(isAuth) {
        const token = Cookies.get('token');
        console.log("[info] updating alert ID:", alert.alert_id);
        try {
          await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_PREFIX}/alert/update`,
            data: alert,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          notify("The alert was successfully updated.");
        } catch (error) {
          notify("An error occurred while updating the alert.", "error");
          console.error("[error] while updating alert:", error);
        }
     }
     else {
        notify("Your session has expired, please relogin.", "error",)
     }
  }

  const handlAlertMarkerDrag = (coord) => {
    setAlertMarker((prevAlertMarker) => ({
      ...prevAlertMarker,
      latitude: coord.latitude,
      longitude: coord.longitude, 
    }));
  };

  const validCoord = (position) => {
    return Number.isFinite(position[0]) && Number.isFinite(position[1])
  };

  const submitAlert = () => {
    const P = {
      latitude: 40.61467785567505,
      longitude: -8.729404059442405,
    };
    const R = 1200; // meters
    const randomPoint = randomLocation.randomCirclePoint(P, R);

    setAlertMarker({
      latitude: randomPoint.latitude,
      longitude: randomPoint.longitude,
      color: "black", 
    });

    setTabIdx(TAB_INDEX_NONE)
  };

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: 40.628,
    longitude: -8.735,
    zoom: 13,
    maxZoom: 18
  });

  const [alertMarker, setAlertMarker] = useState({
    latitude: viewport.latitude,
    longitude: viewport.longitude,
    color: "black"
  });

  const resize = () => {
    setViewport({
      ...viewport,
      width: "100%",
      height: "100%",
    });
  };

  const collapsed = useSelector((state) => state.collapsed.value);

  const selectAlertImg = useCallback(
    (selectedAlertImg) => {
      setAlertImg(selectedAlertImg);

      // console.log(selectedAlertImg);
      // console.log(mapCenter.latitude, mapCenter.longitude);
  
      setAlertMarker({
        latitude: mapCenter.latitude,
        longitude: mapCenter.longitude,
        color: "black",
      });
    },
    [mapCenter]
  );

  const toggle = (rsu) => {
    setRsuToggled(true);
    setWsuToggled(false);
    setRadarToggled(false);
    setActiveSectionRsu("Heartbeats");
    const idx = rsuList.findIndex((x) => x._id === rsu._id);
    setRsuIdx(idx);
    setViewport({
      ...viewport,
      longitude: rsu.message[0].position[1],
      latitude: rsu.message[0].position[0],
      zoom: 14,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  async function toggleRsu(id, toggle) {
    const token = Cookies.get('token');

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_PREFIX}/rsu/${id}`,
      data: {
          active: toggle
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (toggle) {
      console.log("RSU ", id, " has been placed on the the map");
    }else {
      console.log("RSU ", id, " was removed from the map");
    }
  };

  const toggleWsuTab = (wsu) => {
    setWsuToggled(true);
    setRadarToggled(false);
    setRsuToggled(false);
    const idx = wsuList.findIndex((x) => x._id === wsu._id);
    setWsuIdx(idx);
    setViewport({
      ...viewport,
      longitude: wsu.message[0].longitude,
      latitude: wsu.message[0].latitude,
      zoom: 14,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  async function toggleWsu(id, toggle) {
    const token = Cookies.get('token');

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_PREFIX}/wsu/${id}`,
      data: {
          active: toggle
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (toggle) {
      console.log("RSU ", id, " has been placed on the the map");
    }else {
      console.log("RSU ", id, " was removed from the map");
    }
  };

  const toggleRadar = (radar_key) => {
    const idx = radar_keys.findIndex((x) => x === radar_key);
    setRadarIdx(idx);
    setWeatherIdx(null);
    setRadarToggled(true);
    setWeatherToggled(false);
    setRsuToggled(false);
    setWsuToggled(false);

    setViewport({
      ...viewport,
      latitude: rsuList.filter((x) => x._id == radar_key)[0].message[0]
        .position[0],
      longitude: rsuList.filter((x) => x._id == radar_key)[0].message[0]
        .position[1],
      zoom: 14,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  const toggleWeather = (wsu_key) => {
    // console.log(wsu_key);
    const idx = wsu_keys.findIndex((x) => x === wsu_key);
    setWeatherIdx(idx);
    setRadarIdx(null);
    setWeatherToggled(true);
    setRadarToggled(false);
    setRsuToggled(false);
    setWsuToggled(false);

    // setViewport({
    //   ...viewport,
    //   latitude: wsuList.filter((x) => x._id == wsu_key)[0].message[0]
    //     .position[0],
    //   longitude: wsuList.filter((x) => x._id == wsu_key)[0].message[0]
    //     .position[1],
    //   zoom: 14,
    //   transitionDuration: 1000,
    //   transitionInterpolator: new FlyToInterpolator(),
    // });
  };

  // Notifications
  function notify(message, type) {
    if (type === "success")
      toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    else if (type === "error")
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    else 
      toast.info(message, {
        position: toast.POSITION.BOTTOM_CENTER
    });
  }

  // Callback function to update the map center when the user interacts with the map
  const handleInteractionStateChange = useCallback(
    (interactionState) => {
      if (
        interactionState.isDragging &&
        interactionState.pointerDownPicks &&
        interactionState.pointerDownPicks.length > 0
      ) {
        // Map is being dragged and pointerDownPicks array is not empty
        setMapCenter({
          latitude: interactionState.pointerDownPicks[0].groundCoord[1],
          longitude: interactionState.pointerDownPicks[0].groundCoord[0],
        });
      }
    },
    []
  );

  const handleViewportChange = useCallback(
    (newViewport) => {
      setViewport(newViewport);
      setMapCenter({
        latitude: newViewport.latitude,
        longitude: newViewport.longitude,
      });
    },
    []
  );

  const removeTum = (id) => {
    updateTumList(tumList => tumList.filter(tum => tum.tempID !== id));
  };

  const handleTumClick = (tumData) => {
    setPopupDataTUM(tumData);
  };

  const closeTumPopup = () => {
    setPopupDataTUM(null);
  };

  // Close the popups after opening the dashboards
  useEffect(() => {
    if((tabIndex === TAB_INDEX_DASHBOARD) && (radarToggled || weatherToggled)) setClosePopup(true);
  },[tabIndex, radarToggled, weatherToggled]);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_IO_ORIGIN}`, {path: `${process.env.REACT_APP_IO_PATH}` });

    socket.on("connect", () => {
      const engine = socket.io.engine;
      console.log("[info] socket io engine: " +engine.transport.name);

      socket.on("connect_error", (error) => {
        console.log(`[error] connect_error due to ${error.message}`);
      });

      socket.on("heartbeat_init", (result) => {
        setRsuList([...result]);
      });

      socket.on("heartbeatWsu_init", (result) => {
        setWsuList([...result]);
      });

      socket.on("heartbeat_update", (result) => {
        setRsuList((rsuList) => {
          const tmp = [...rsuList];
          const idx = rsuList.findIndex((rsu) => rsu._id === result._id);
          tmp[idx] = result;
          return tmp;
        });
      });

      socket.on("heartbeatWsu_update", (result) => {
        setWsuList((wsuList) => {
          const tmp = [...wsuList];
          const idx = wsuList.findIndex((wsu) => wsu._id === result._id);
          tmp[idx] = result;
          return tmp;
        });
      });

      socket.on("cpm_update", (result) => {
        setCars(result);
      });

      socket.on("cam_update", (result) => {
        setCamList(result);
      });

      socket.on("bsm_update", (result) => {
        setBsmList(result);
      });

      socket.on("denm_update", (result) => {
        console.log("[info] received denm_update from io");

        // for (let alert_id in result) {
        //     let alert = result[alert_id];
        //     console.log(`alert_id: ${alert._id}, station_id: ${alert.station_id}, sequence_number: ${alert.sequenceNumber}`);
        // }
        
        updateAlertList(result);
      });

      socket.on("vam_update", (result) => {
        updateVamList(result);
      });

      socket.on("vsm_update", (result) => {
        updateVsmList(result);
      });

      socket.on("tam_update", (result) => {
        // console.log('Received tam_update:', result);
        //For each element of the result, print each element of the map
        // result.forEach((element) => {
        //     console.log('Tam data element:', element);
        // });
        updateTamList(result);
      });

      socket.on("tum_update", (result) => {
        // console.log('Received tum_update:', result);
        updateTumList(result);
      });
      
      socket.io.on("error", (error) => {
        console.log("[error] generic: " +error);
      });

      socket.io.on("reconnect", (attempt) => {
        console.log("[warning] reconnecting to socket io: " +attempt);
      });

      socket.io.on("reconnect_error", (error) => {
        console.log("[error] reconecting: " +error);
      });

      engine.on("close", (reason) => {
        console.log("[warning] closing socket: " +reason);
      });
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  });

  useEffect(() => {
    var i = 0;
    const tmp = setInterval(() => {
      resize();
      i++;
      if (i === 500 / 20) clearTimeout(tmp);
    }, 20);
  }, [collapsed]);

  useEffect(() => {
    checkAuthentication();
  }, []);

  const mapRef = useRef(null);

  
  // Attempt of adding 3D objects to the map
  // useEffect(() => {
  //   if (mapRef.current) {
  //     const map = mapRef.current;

  //     const tb = (window.tb = new Threebox(
  //       map,
  //       map.getCanvas().getContext('webgl'),
  //       {
  //           defaultLights: true
  //       }
  //     ));

  //     map.on('style.load', () => {
  //       map.addLayer({
  //         id: 'custom-threebox-model',
  //         type: 'custom',
  //         renderingMode: '3d',
  //         onAdd: function () {
  //             // const scale = 3.2;
  //             const options = {
  //                 // obj: 'https://docs.mapbox.com/mapbox-gl-js/assets/metlife-building.gltf',
  //                 obj: car,
  //                 type: 'gltf',
  //                 // scale: { x: scale, y: scale, z: 2.7 },
  //                 scale: 10000,
  //                 units: 'meters',
  //                 rotation: { x: 90, y: -90, z: 0 }
  //             };

  //             tb.loadObj(options, (model) => {
  //                 model.setCoords([-8.657072, 40.629540]);
  //                 model.setRotation({ x: 0, y: 0, z: 241 });

  //                 // // Change model color
  //                 // model.traverse((child) => {
  //                 //   if (child.isMesh) {
  //                 //     child.material = new THREE.MeshPhysicalMaterial({ color: 0xffffff }); 
  //                 //   }
  //                 // });

  //                 tb.add(model);
  //             });
  //         },

  //         render: function () {
  //             tb.update();
  //         }
  //     });
  //     });
  //   }
  // }, [mapRef]);


  const createPolygonCoordinates = (node1, node2, laneWidth) => {
    if (!node1 || !node2 || !laneWidth) return [];

    const lat1 = node1.lat / 10000000;
    const lon1 = node1.lon / 10000000;
    const lat2 = node2.lat / 10000000;
    const lon2 = node2.lon / 10000000;

    // Convert laneWidth from centimeters to degrees
    const width = (laneWidth / 100) / 111320; // 1 degree of latitude is approximately 111.32 km

    // Calculate the angle of the line
    const angle = Math.atan2(lat2 - lat1, lon2 - lon1);

    // Calculate the perpendicular angle
    const perpAngle = angle + Math.PI / 2;

    // Calculate the offsets
    const dx = (width / 2) * Math.cos(perpAngle);
    const dy = (width / 2) * Math.sin(perpAngle);

    // Calculate the four corners of the rectangle
    return [
      [lon1 - dx, lat1 - dy],
      [lon1 + dx, lat1 + dy],
      [lon2 + dx, lat2 + dy],
      [lon2 - dx, lat2 - dy],
      [lon1 - dx, lat1 - dy]
    ];
  };

  const currentSources = useRef(new Set());

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;
      // console.log("map: ", map);

      const currentTamIds = new Set(tamList.flatMap((tam, tamIdx) =>
        [
          ...tam.tollZoneLanes.map((lane, laneIdx) => `toll-zone-${tam.tollChargerInfo.tollChargerId}-${tam.tollChargerInfo.tollPointId}-${tamIdx}-${laneIdx}`),
          ...tam.approachLanes.map((lane, laneIdx) => `approach-lane-${tam.tollChargerInfo.tollChargerId}-${tam.tollChargerInfo.tollPointId}-${tamIdx}-${laneIdx}`),
          ...tam.approachLanes.map((lane, laneIdx) => `dashed-outline-${tam.tollChargerInfo.tollChargerId}-${tam.tollChargerInfo.tollPointId}-${tamIdx}-${laneIdx}`)
        ]
      ));

      const layersToRemove = [];
      const sourcesToRemove = [];

      // Collect sources and layers not in the current tamList
      currentSources.current.forEach((sourceId) => {
        if (!currentTamIds.has(sourceId)) {
          layersToRemove.push(sourceId, `dashed-outline-${sourceId}`);
          sourcesToRemove.push(sourceId);
          currentSources.current.delete(sourceId);
        }
      });

      // Remove layers
      layersToRemove.forEach((layerId) => {
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }
      });

      // Remove sources
      sourcesToRemove.forEach((sourceId) => {
        if (map.getSource(sourceId)) {
          map.removeSource(sourceId);
        }
      });

      tamList.forEach((tam, tamIdx) => {
        const tamKey = `${tam.tollChargerInfo.tollChargerId}-${tam.tollChargerInfo.tollPointId}-${tamIdx}`;
        let darkColor = "#1F51FF";
        let lightColor = "#89CFF0";

        if (tam.tollChargesKey === "closedNetworkCharges") {
          if (tam.chargeType === "entry") {
            darkColor = "#228B22";
            lightColor = "#ACE1AF";
          } else {
            darkColor = "#FF0000";
            lightColor = "#FA8072";
          }
        }

        tam.tollZoneLanes.forEach((lane, laneIdx) => {
          const sourceId = `toll-zone-${tamKey}-${laneIdx}`;
          const coordinates = createPolygonCoordinates(lane.nodeList[0], lane.nodeList[1], tam.laneWidth);

          if (!map.getSource(sourceId)) {
            map.addSource(sourceId, {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [coordinates],
                },
                properties: {
                  description: `RSU ${tam.rsu_stationId}, Tolling Zone ${tamIdx + 1}, Lane ${laneIdx + 1}`,
                  tollChargerId: tam.tollChargerInfo.tollChargerId,
                  tollPointId: tam.tollChargerInfo.tollPointId,
                  tollCharges: tam.tollCharges,
                  tollZoneLane: true
                }
              },
            });

            map.addLayer({
              id: sourceId,
              type: 'fill',
              source: sourceId,
              paint: {
                'fill-color': darkColor,
                'fill-opacity': 0.4,
                'fill-outline-color': '#000000'
              },
            });

            currentSources.current.add(sourceId);
          }
        });

        tam.approachLanes.forEach((lane, laneIdx) => {
          const sourceId = `approach-lane-${tamKey}-${laneIdx}`;
          const dashedOutlineLayerId = `dashed-outline-${tamKey}-${laneIdx}`;
          const coordinates = createPolygonCoordinates(lane.nodeList[0], lane.nodeList[1], tam.laneWidth);

          if (!map.getSource(sourceId)) {
            map.addSource(sourceId, {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [coordinates],
                },
                properties: {
                  description: `RSU ${tam.rsu_stationId}, Tolling Zone ${tamIdx + 1}, Lane ${laneIdx + 1}`,
                  tollChargerId: tam.tollChargerInfo.tollChargerId,
                  tollPointId: tam.tollChargerInfo.tollPointId,
                  tollCharges: tam.tollCharges,
                  tollZoneLane: false
                }
              },
            });

            map.addLayer({
              id: sourceId,
              type: 'fill',
              source: sourceId,
              paint: {
                'fill-color': lightColor,
                'fill-opacity': 0.4,
              },
            });

            map.addLayer({
              id: dashedOutlineLayerId,
              type: 'line',
              source: sourceId,
              paint: {
                'line-color': darkColor,
                'line-width': 1,
                'line-dasharray': [4, 3],
              },
            });

            currentSources.current.add(sourceId);
            currentSources.current.add(dashedOutlineLayerId);
          }
        });
      });
    }
  }, [tamList]);

  const handleLayerClick = (event) => {
    const { features, lngLat } = event;
    if (lngLat && typeof lngLat[0] === 'number' && typeof lngLat[1] === 'number') {
      const feature = features && features.find(f => f.layer.type === 'fill' || f.layer.type === 'line');
      if (feature) {
        const tam = tamList.find(t => t.tollChargerInfo.tollChargerId === feature.properties.tollChargerId && t.tollChargerInfo.tollPointId === feature.properties.tollPointId);
        if(tam){
          let tollCharges = [];

          // In the case of an approach lane set the coordinates of the first node of the tolling zone (first lane)
          if(!feature.properties.tollZoneLane) {
            lngLat[0] = tam.tollZoneLanes[0].nodeList[0].lon / 10000000;
            lngLat[1] = tam.tollZoneLanes[0].nodeList[0].lat / 10000000;
          } 

          try {
            tollCharges = JSON.parse(feature.properties.tollCharges);
          } catch (error) {
            console.error('Failed to parse tollCharges:', error);
          }
  
          setPopupInfoTAM({
            longitude: lngLat[0],
            latitude: lngLat[1],
            description: feature.properties.description,
            tollChargerId: feature.properties.tollChargerId,
            tollPointId: feature.properties.tollPointId,
            tollCharges
          });

          setActiveSectionTAM("TollInfo");
        }
      }
    } else {
      console.error('Invalid lngLat values:', lngLat);
    }
  };

  const handleTamListClick = (tam, tamIdx) => {
    const { tollZoneLanes, tollChargerInfo } = tam;
    // console.log(tam.referencePoint);

    const firstLane = tollZoneLanes[0];
    const firstNode = firstLane.nodeList[0];
    const { lon, lat } = firstNode

    setPopupInfoTAM({
      longitude: lon / 10000000,
      latitude: lat / 10000000,
      description: `RSU ${tam.rsu_stationId}, Tolling Zone ${tamIdx + 1}, Lane 1`,
      tollChargerId: tollChargerInfo.tollChargerId,
      tollPointId: tollChargerInfo.tollPointId,
      tollCharges: JSON.parse(tam.tollCharges)
    });

    setViewport({
      ...viewport,
      longitude: lon / 10000000,
      latitude: lat / 10000000,
      zoom: 14,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    });

    // Close RSU Tab
    setRsuToggled(!RsuToggled); 
    setRsuIdx(null);
  }

  const toggle3D = () => {
    setIs3D((prev) => !prev);
  };

  // Toggle between 2D and 3D versions of the map view
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;

      const handleToggle3D = () => {
        if (!map.style._loaded) {
          map.once('style.load', handleToggle3D);
          return;
        }

        if (map.getLayer('3d-buildings')) {
          map.setLayoutProperty('3d-buildings', 'visibility', is3D ? 'visible' : 'none');
        } else {
          map.addLayer({
            id: '3d-buildings',
            source: 'composite',
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: 15,
            paint: {
              'fill-extrusion-color': '#aaa',
              'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height'],
              ],
              'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height'],
              ],
              'fill-extrusion-opacity': 0.6,
            },
          });
        }
      };

      handleToggle3D();

      map.on('style.load', handleToggle3D);

      return () => {
        map.off('style.load', handleToggle3D);
      };
    }
  }, [is3D]);
  
  return (
    <MapContainer>
      <Map
        {...viewport}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onViewportChange={handleViewportChange}
        onInteractionStateChange={handleInteractionStateChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        ref={(ref) => (mapRef.current = ref && ref.getMap())}
        onClick={handleLayerClick}
      >
        {/* RSU Stations */}
        {rsuList
          .filter((rsu) => rsu.active && validCoord(rsu.message[0].position))
          .map((rsu) => (
            <RSU key={rsu._id} {...rsu} onClick={(e) => toggle(rsu)} closePopup={closePopup} />
        ))}

        {/* Weather Stations */}
        {wsuList.filter((wsu) => wsu.active && validCoord([wsu.message[0].longitude, wsu.message[0].latitude])).map((wsu) => (
          <WSU key={wsu._id} {...wsu} onClick={(e) => toggleWsuTab(wsu)} />
        ))}

        {/* CAM's */}
        {camList.filter((cam) => validCoord([cam.longitude, cam.latitude])).map((cam, idx) => 
          <Cam key={cam.stationId} {...cam} idx={idx} /> 
        )}

        {/* Connect CAM's with the RSU that has an id that matches CAM's rsu_stationId */}
        {camList
          .filter((cam) => validCoord([cam.longitude, cam.latitude]))
          .map((cam, idx) => {
            const matchingRsuList = rsuList.filter((rsu) => cam.rsu_stationIds.includes(rsu._id));
            if (matchingRsuList.length > 0) {
              const camLines = matchingRsuList.map((rsu, index) => {
                const key = `cam-${cam.stationId}-${rsu._id}`;
                const sourceId = `line-source-cam-${cam.stationId}-${rsu._id}`;
                const backgroundLayerId = `line-background-layer-cam-${cam.stationId}-${rsu._id}`;
                const dashedLayerId = `line-dashed-layer-cam-${cam.stationId}-${rsu._id}`;
                
                // Check if the source exists, create it if it doesn't
                if (!mapRef.current.getSource(sourceId)) {
                  mapRef.current.addSource(sourceId, {
                    type: 'geojson',
                    data: {
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: [
                          [cam.longitude, cam.latitude],
                          [rsu.message[0].position[1], rsu.message[0].position[0]],
                        ],
                      },
                    },
                  });
                } else {
                  // If source exists, update its coordinates
                  mapRef.current.getSource(sourceId).setData({
                    type: 'Feature',
                    geometry: {
                      type: 'LineString',
                      coordinates: [
                        [cam.longitude, cam.latitude],
                        [rsu.message[0].position[1], rsu.message[0].position[0]],
                      ],
                    },
                  });
                }

                return (
                  <React.Fragment key={key}>
                    <Layer
                      key={backgroundLayerId}
                      id={backgroundLayerId}
                      type="line"
                      source={sourceId}
                      paint={{
                        'line-color': 'yellow',
                        'line-width': 10,
                        'line-opacity': 0.4,
                      }}
                    />
                    <Layer
                      key={dashedLayerId}
                      id={dashedLayerId}
                      type="line"
                      source={sourceId}
                      paint={{
                        'line-color': 'yellow',
                        'line-width': 10,
                        'line-dasharray': [0, 4, 3],
                      }}
                    />
                  </React.Fragment>
                );
              });

              return camLines;
            }
            return null; // Return null for unmatched CAM-RSU pairs
          })
        }

        {/* BSM's */}
        {bsmList.filter((bsm) => validCoord([bsm.longitude, bsm.latitude])).map((bsm, idx) =>
          <Bsm key={bsm.stationId} {...bsm} idx={idx} />
        )}

        {/* Connect BSM's with the RSU that has an id that matches BSM's rsu_stationId  */}
        {bsmList
          .filter((bsm) => validCoord([bsm.longitude, bsm.latitude]))
          .map((bsm, idx) => {
            const matchingRsuList = rsuList.filter((rsu) => bsm.rsu_stationIds.includes(rsu._id));
            if (matchingRsuList.length > 0) {
              const bsmLines = matchingRsuList.map((rsu, index) => {
                const key = `bsm-${bsm.stationId}-${rsu._id}`;
                const sourceId = `line-source-bsm-${bsm.stationId}-${rsu._id}`;
                const backgroundLayerId = `line-background-layer-bsm-${bsm.stationId}-${rsu._id}`;
                const dashedLayerId = `line-dashed-layer-bsm-${bsm.stationId}-${rsu._id}`;
             
                // Check if the source exists, create it if it doesn't
                if (!mapRef.current.getSource(sourceId)) {
                  mapRef.current.addSource(sourceId, {
                    type: 'geojson',
                    data: {
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: [
                          [bsm.longitude, bsm.latitude],
                          [matchingRsuList[0].message[0].position[1], matchingRsuList[0].message[0].position[0]],
                        ],
                      },
                    },
                  });
                } else {
                  // If source exists, update its coordinates
                  mapRef.current.getSource(sourceId).setData({
                    type: 'Feature',
                    geometry: {
                      type: 'LineString',
                      coordinates: [
                        [bsm.longitude, bsm.latitude],
                        [matchingRsuList[0].message[0].position[1], matchingRsuList[0].message[0].position[0]],
                      ],
                    },
                  });
                }

                return (
                  <React.Fragment key={key}>
                    <Layer
                      key={backgroundLayerId}
                      id={backgroundLayerId}
                      type="line"
                      source={sourceId}
                      paint={{
                        'line-color': 'yellow',
                        'line-width': 10,
                        'line-opacity': 0.4,
                      }}
                    />
                    <Layer
                      key={dashedLayerId}
                      id={dashedLayerId}
                      type="line"
                      source={sourceId}
                      paint={{
                        'line-color': 'yellow',
                        'line-width': 10,
                        'line-dasharray': [0, 4, 3],
                      }}
                    />
                  </React.Fragment>
               );
              });

              return bsmLines;
            }
            return null; // Return null for unmatched BSM-RSU pairs
        })}

        {/* CPM's */}
        {Object.keys(cars).map((rsu, idx) =>
          cars[rsu]
            .filter((rsu) => validCoord([rsu[0], rsu[1]]))
            .map((rsu, index) => <Cpm key={rsu[5]} data={rsu} idx={index} handleCPMClick={handleCPMClick} clearSelectedCPM={clearSelectedCPM} />)
        )}

        {/* Connection between selected CPM and corresponding RSU */}
        {selectedCar && (
          <Source
            key={`line-source-cpm-${selectedCPM.cpm_id}`}
            type="geojson"
            data={{
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [
                  [selectedCar[1], selectedCar[0]],
                  [
                    selectedCPM.matchingRsu.message[0].position[1],
                    selectedCPM.matchingRsu.message[0].position[0],
                  ], 
                ],
              },
            }}
          >
            <Layer
              key={`line-background-layer-cpm-${selectedCPM.cpm_id}`}
              type="line"
              paint={{
                'line-color': 'lightblue',
                'line-width': 10,
                'line-opacity': 0.5,
              }}
            />
            <Layer
              key={`line-dashed-layer-cpm-${selectedCPM.cpm_id}`}
              type="line"
              paint={{
                'line-color': 'lightblue',
                'line-width': 10,
                'line-dasharray': [0, 4, 3],
              }}
            />
          </Source>
        )}

        {mapRef.current && <DashArrayAnimation map={mapRef.current} rsuList={rsuList} camList={camList} bsmList={bsmList} selectedCPM={selectedCPM} />}
        
        {/* VAM's */}
        {vamList.filter((vam) => validCoord([vam.longitude, vam.latitude])).map((vam, idx) =>
          <Vam key={vam.stationId} {...vam} idx={idx} />
        )}

        {/* VSM's */}
        {vsmList.filter((vsm) => validCoord([vsm.longitude, vsm.latitude])).map((vsm, idx) =>
          <Vsm key={vsm.stationId} {...vsm} idx={idx} />
        )}

        {/* TUM's */}
        {tumList.map((tum, idx) =>
          tum.locations.map((location, locIdx) => (
            validCoord([location.longitude, location.latitude]) && (
              <Tum
                key={`${tum.tempID}-${idx}-${locIdx}`}
                id={tum.tempID}
                stationId={tum.tempID}
                locations={tum.locations}
                vehicleType={tum.vehicleType}
                charge={tum.charge}
                removeMarker={removeTum}
                onMarkerClick={handleTumClick}
                isPopupOpen={popupDataTUM && popupDataTUM.stationId === tum.tempID}
              />
            )
          ))
        )}

        {/* TAM's Popup */}
        {popupInfoTAM && (
          <Popup
            longitude={popupInfoTAM.longitude}
            latitude={popupInfoTAM.latitude}
            closeOnClick={false}
            onClose={() => setPopupInfoTAM(null)}
          >
            <PopupContent>
              <CustomNavTAM
                  appearance="subtle"
                  active={activeSectionTAM}
                  onSelect={setActiveSectionTAM}
              />
              <PopupTitle>{popupInfoTAM.description}</PopupTitle>

              {activeSectionTAM === "TollInfo" ? (
                <PopupList>
                  {popupInfoTAM.tollCharges.length > 0 ? (
                    popupInfoTAM.tollCharges.map((charge, idx) => (
                      <PopupListItem key={idx}>
                        {/* {charge.vehType}: ${charge.charges.paymentFeeAmount} {charge.charges.paymentFeeUnit} */}
                        {charge.vehType}: ${charge.charges.paymentFeeAmount} 
                      </PopupListItem>
                    ))
                  ) : (
                    <PopupListItem>No toll charges available</PopupListItem>
                  )}
                </PopupList>
              ) : null}

              {activeSectionTAM === "Transactions" ? (
                <div>
                    <TumMessageList tollChargerId={popupInfoTAM.tollChargerId} tollPointId={popupInfoTAM.tollPointId} />
                </div>
              ) : null}
            </PopupContent>
          </Popup>
        )}

        {/* Toggling Button for 2D and 3D versions of the map */}
        <ToggleContainer>
          <Label>2D</Label>
          <Switch
            onChange={toggle3D}
            checked={is3D}
            offColor="#888"
            onColor="#000"
            uncheckedIcon={false}
            checkedIcon={false}
          />
          <Label>3D</Label>
        </ToggleContainer>

        {isAuthenticated && ((selectedAlertImg && (tabIndex === TAB_INDEX_CREATE_ALERT)) || (showUpdateAlert && (tabIndex === TAB_INDEX_UPDATE_ALERT))) ? (
          <PinPoint
            lat={alertMarker.latitude}
            long={alertMarker.longitude}
            onDrag={handlAlertMarkerDrag}
            markerColor={alertMarker.color}
          ></PinPoint>
        ) : null}

        {/* Calls Denm component and renders  denm image and popup */}
        {Object.keys(alertList).map((alert_id) => (
          <Denm
            key={alert_id}
            station_id={alertList[alert_id].station_id}
            latitude={alertList[alert_id].latitude}
            longitude={alertList[alert_id].longitude}
            sub_cause_name={alertList[alert_id].sub_cause_name}
            alertList={alertList}
            denmID={alert_id}
            alertClicked={clickedAlertId === alert_id}
            alertID={clickedAlertId}
            onCancelAlert={handleCancelAlert}
            onUpdateAlert={handleUpdateAlert}
          ></Denm>
        ))}
      </Map>

      <TumPopup isOpen={!!popupDataTUM} onClose={closeTumPopup} tumData={popupDataTUM || {}} />
        
      {/* --------------- RSU TAB SECTION  ---------------*/}
      {RsuToggled ? (<RsuMainDiv>
          <RsusTabInfoDiv isAuthenticated={isAuthenticated}>
            <div>
              {isAuthenticated ? 
                <CustomNavAdmin
                  appearance="subtle"
                  active={activeSectionRsu}
                  onSelect={setActiveSectionRsu}
                />
                :
                <CustomNav
                  appearance="subtle"
                  active={activeSectionRsu}
                  onSelect={setActiveSectionRsu}
                />
              }   
            </div>

            {activeSectionRsu === "Heartbeats" ? (
              <div>
                <RsuIDInfoDiv>
                  <h5>RSU Station ID: {rsuList[idx_RSU]._id} </h5>
                </RsuIDInfoDiv>
                {
                  <RsuTabInfo
                    key={idx_RSU}
                    {...rsuList[idx_RSU]}
                    toggleRsu={toggleRsu}
                    // parentCallBack={callback}
                  />
                }
              </div>
            ) : null}
            
            {activeSectionRsu === "History" ? (
              <div>
                  <RsuMessageList rsu_id={rsuList[idx_RSU]._id} />
              </div>
            ) : null}

            {activeSectionRsu === "Tolling" ? (
              <div>
                  <RsuTollingZonesList rsuId={rsuList[idx_RSU]._id} tamList={tamList} handleClick={handleTamListClick}/>
              </div>
            ) : null}

            {activeSectionRsu === "Camera" ? (
              <div>
                <RsuIDInfoDiv>
                  <h5>RSU Station ID: {rsuList[idx_RSU]._id} </h5>
                </RsuIDInfoDiv>
                <div>
                  <CameraStream ffmpeg_ip={window.location.hostname} rsu_id={rsuList[idx_RSU]._id} />
                </div>
              </div>
            ) : null}
          </RsusTabInfoDiv>
      </RsuMainDiv> ) : null}

      {RsuToggled ? <ArrowCloseOpen
        onClick={() => { setRsuToggled(!RsuToggled); setRsuIdx(null); }}
        collapsed={RsuToggled === true}
      >
        <IoIosArrowBack size={50} />
      </ArrowCloseOpen> : null}


      {/* --------------- WSU TAB SECTION  ---------------*/}
      {WsuToggled ? 
        (<RsuMainDiv>
          <WsusTabInfoDiv isAuthenticated={isAuthenticated}>
            <CustomNavWSU
              appearance="subtle"
              active={activeSectionWsu}
              onSelect={setActiveSectionWsu}
            />

            {activeSectionWsu === "Heartbeats" ? (
              <div>
                <WsuIDInfoDiv>
                  <h5>WSU Station ID: {wsuList[idx_WSU]._id} </h5>
                </WsuIDInfoDiv>

                <WsuTabInfo
                  key={idx_WSU}
                  wsu_id={wsuList[idx_WSU]._id}
                  temperature={wsuList[idx_WSU].message[0].temperature}
                  humidity={wsuList[idx_WSU].message[0].humidity}
                  wind_speed={wsuList[idx_WSU].message[0].wind_speed}
                  wind_direction={wsuList[idx_WSU].message[0].wind_direction}
                  solar_radiation={wsuList[idx_WSU].message[0].solar_radiation}
                  atm_pressure={wsuList[idx_WSU].message[0].atmospheric_pressure}
                  precipitation={wsuList[idx_WSU].message[0].precipitation}
                  active={wsuList[idx_WSU].active}
                  toggleWsu={toggleWsu}
                />
              </div>
            ) : null}
            
            {activeSectionWsu === "History" ? (
              <WsuMessageList wsu_id={wsuList[idx_WSU]._id} />
            ) : null}

          </WsusTabInfoDiv>
      </RsuMainDiv> ) : null}
      
      {WsuToggled ? <ArrowCloseOpen
        onClick={() => { setWsuToggled(!WsuToggled); setWsuIdx(null); }}
        collapsed={WsuToggled === true}
      >
        <IoIosArrowBack size={50} />
      </ArrowCloseOpen> : null}


      {/* --------------- MAIN TAB SECTION  ---------------*/}
      <RightTab tabIdx={tabIndex}>
        <RightTabHeaderDiv onClick={() => setTabIdx(tabIndex === TAB_INDEX_RSU_LIST ? TAB_INDEX_NONE : TAB_INDEX_RSU_LIST)}>
          <span>Roadside Units</span>
          <RsuTabIcon collapsed={tabIndex === TAB_INDEX_RSU_LIST}>
            <IoIosArrowDown />
          </RsuTabIcon>
        </RightTabHeaderDiv>
        
        {tabIndex === TAB_INDEX_RSU_LIST && (
          <StationsTabRel>
            {(rsuList.sort((a, b) => a._id - b._id).map((rsu, index) => (
              <RsuBar
                  key={rsu._id}
                  {...rsu}
                  selected={idx_RSU === index}
                  onClick={() => toggle(rsu)}
                  toggleRsu={toggleRsu}
                />
              ))
            )}
          </StationsTabRel>
        )}

        <RightTabHeaderDiv onClick={() => setTabIdx(tabIndex === TAB_INDEX_WSU_LIST ? TAB_INDEX_NONE : TAB_INDEX_WSU_LIST)}>
          <span>Weather Stations</span>
          <RsuTabIcon collapsed={tabIndex === TAB_INDEX_WSU_LIST}>
            <IoIosArrowDown />
          </RsuTabIcon>
        </RightTabHeaderDiv>
        
        {tabIndex === TAB_INDEX_WSU_LIST && (
          <StationsTabRel>
            {(wsuList.sort((a, b) => a._id - b._id).map((wsu, index) => (
              <WsuBar
                  key={wsu._id}
                  {...wsu}
                  selected={idx_WSU === index}
                  onClick={() => toggleWsuTab(wsu)}
                  toggleWsu={toggleWsu}
                />
              ))
            )}
          </StationsTabRel>
        )}

        {isAuthenticated ?
          <RightTabHeaderDivHiddenOnMobile onClick={() => setTabIdx(tabIndex === TAB_INDEX_CREATE_ALERT ? TAB_INDEX_NONE : TAB_INDEX_CREATE_ALERT)}>
            <span>Create Alert</span>
            <CreateAlertTabIcon collapsed={tabIndex === TAB_INDEX_CREATE_ALERT}>
              <IoIosArrowDown />
            </CreateAlertTabIcon>
          </RightTabHeaderDivHiddenOnMobile>
          : null
        }

        {tabIndex === TAB_INDEX_CREATE_ALERT ? (
          <SelectTabRel>
              <AlertForm
                selectedAlertImg={selectAlertImg}
                pinCoordinates={alertMarker}
                submitAlert={submitAlert}
                rsu_array={rsuList}
              />
          </SelectTabRel>
        ) : null}
        

        <RightTabHeaderDiv onClick={() => setTabIdx(tabIndex === TAB_INDEX_ALERTS_HISTORY ? TAB_INDEX_NONE : TAB_INDEX_ALERTS_HISTORY)}>
          <span>Alert History</span>
          <DisplayAlertTabIcon collapsed={tabIndex === TAB_INDEX_ALERTS_HISTORY}>
            <IoIosArrowDown />
          </DisplayAlertTabIcon>
        </RightTabHeaderDiv>

        {tabIndex === TAB_INDEX_ALERTS_HISTORY ? (
          <SelectTabRel>
            <DisplayAlerts onAlertClick={handleAlertClick} onCancelAlert={handleCancelAlert} onUpdateAlert={handleUpdateAlert}/>
          </SelectTabRel>
        ): null}
        

        {showUpdateAlert && isAuthenticated  ?
          <RightTabHeaderDiv onClick={() => setTabIdx(tabIndex === TAB_INDEX_UPDATE_ALERT ? TAB_INDEX_NONE : TAB_INDEX_UPDATE_ALERT)}>
            <span>Update Alert</span>
            <CreateAlertTabIcon collapsed={tabIndex === TAB_INDEX_UPDATE_ALERT}>
              <IoIosArrowDown />
            </CreateAlertTabIcon>
          </RightTabHeaderDiv>
          : null
        }

        {tabIndex === TAB_INDEX_UPDATE_ALERT && showUpdateAlert ? (
          <SelectTabRel>
            <UpdateForm 
              pinCoordinates={alertMarker}
              submitUpdateAlert={submitUpdateAlert}
              cancelUpdate={cancelUpdate}
              alert={updateAlert} 
            />
          </SelectTabRel>
        ) : null}


        {/* --------------- RADAR STATISTICS SECTION  ---------------*/}
        <RightTabHeaderDivHiddenOnMobile onClick={() => setTabIdx(tabIndex === TAB_INDEX_DASHBOARD ? TAB_INDEX_NONE : TAB_INDEX_DASHBOARD)}>
          <span>Dashboards</span>
          <DisplayAlertTabIcon collapsed={tabIndex === TAB_INDEX_DASHBOARD}>
            <IoIosArrowDown />
          </DisplayAlertTabIcon>
        </RightTabHeaderDivHiddenOnMobile>
        
        {tabIndex === TAB_INDEX_DASHBOARD ? (
        <SelectTabRel>
          {/* <h5>Traffic</h5> */}
          {Object.keys(cpm_radars).map((key, index) => (
              <DashboardsList
                key={key}
                data={cpm_radars[key]}
                k={key}
                type={"RSU"}
                selected={idx_radar === index}
                onClick={() => toggleRadar(key)}
              />
            ))}
          {/* <h5>Weather</h5> */}
          {Object.keys(wsu_list).map((key, index) => (
              <DashboardsList
                key={key}
                data={wsu_list[key]}
                k={key}
                type={"WSU"}
                selected={idx_weather === index}
                onClick={() => toggleWeather(key)}
              />
            ))}
        </SelectTabRel>
        ) : null}
      </RightTab>
      
      {/* Traffic Charts */}
      {radarToggled && tabIndex === TAB_INDEX_DASHBOARD ? (
        // <StatisticsDiv activeSection={activeSectionStat}>
        <StatisticsDiv>
          <HeaderStatistics>
            RSU Station: {radar_keys[idx_radar]} -{" "}
            {cpm_radars[radar_keys[idx_radar]]}
          </HeaderStatistics>

          <CustomNavStatistics
              appearance="subtle"
              active={activeSectionStat}
              onSelect={setActiveSectionStat}
          />

          <ChartsContainer>
            <TrafficChartTab
                station_id={radar_keys[idx_radar]}
                activeSection={activeSectionStat}
            />
          </ChartsContainer>
        </StatisticsDiv>
      ) : null}

      {/* Weather Charts */}
      {weatherToggled && tabIndex === TAB_INDEX_DASHBOARD ? (
        <WeatherStatisticsDiv>
          <HeaderStatistics>
            WSU Station: {wsu_keys[idx_weather]} -{" "}
            {wsu_list[wsu_keys[idx_weather]]}
          </HeaderStatistics>

          <CustomNavWeather
              appearance="subtle"
              active={activeSectionWeather}
              onSelect={setActiveSectionWeather}
          />

          <ChartsContainer>
            <WeatherChartTab
                station_id={wsu_keys[idx_weather]}
                activeSection={activeSectionWeather}
            />
          </ChartsContainer>
        </WeatherStatisticsDiv>
      ) : null}
      
    </MapContainer>
    
  );
}

export default Map_page;